import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { BackButton, useInitData } from '@vkruglikov/react-telegram-web-app';
import { useState, useEffect } from 'react';
import Video from './Video';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { autoLinkMd } from "react-markdown-autolink";
import Markdown from "react-markdown";
import { ReactComponent as DownloadIcon } from './images/download-svgrepo-com (1).svg'
import { ReactComponent as BuyIcon } from './images/purchase-cash-register-svgrepo-com.svg'
import { ReactComponent as CheckBox } from './images/checkbox-svgrepo-com.svg'
import chapterIcon1 from './images/59ab4612-c0bf-43a9-b68c-7b243aae7229.jpg'
import mainLogo from './images/image.png'

function Intro(props) {
  const [initDataUnsafe, initData] = useInitData();
  console.log(props.purchased)
  return (
    <div className='Intro' style={{ fontSize: '0.8rem' }}>
      {!props.purchased && <p>
        لطفا اشتراک خود را تهیه کنید.
      </p>}
      {
        props.purchased && <p>
          شما اشتراک فعال دارید
        </p>
      }
    </div>
  )
}

function Section(props) {
  const [downloadLink, setDownloadLink] = useState('#')
  const handleDownloadClick = () => {
    if (props.purchased) {
      axios.get(`https://scambot.levothyroxine.uno/api/sections/download/${props.id}`,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('jwt-token')}` } }
      ).then(
        response => {
          setDownloadLink(response.data + `&response-content-disposition=attachment`)
          window.Telegram.WebApp.openLink(response.data);
        }
      )
    } else {
      props.onDownload()
    }
  }
  return <div className='Section'>
    <div className="Section-heading">
      <p style={{ fontSize: '0.8rem' }}>{props.title}</p>
      <a className='Section-download' onClick={handleDownloadClick}>
        دانلود<DownloadIcon style={{ height: '1.3rem', width: '1.3rem', display: 'inline', filter: 'brightness(0) invert(1)' }}></DownloadIcon>
      </a>
    </div>
    {props.purchased && props.description && <Markdown className='Section-description' style={{ fontSize: '0.8rem' }}>{autoLinkMd(props.description)}</Markdown>}
    {props.purchased && <Video config_url={props.config_url}></Video>}
  </div>
}

function Chapter(props) {
  console.log(props.title)
  return (
    <div className='Chapter'>
      <div className='Chapter-description'>
        <p style={{ fontSize: '0.8rem', margin: '0.1rem 0' }}>{props.description}</p>
        {props.sections.map((section, index) => { return (<Section title={section.title} onDownload={props.onDownload} purchased={props.purchased} description={section.description} file_id={section.file_id} config_url={section.config_url} id={section._id} file_name={section.file_id} isLast={index === props.sections.length - 1 ? true : false} />) })}
      </div>
    </div>
  )
}



function App() {
  const [initDataUnsafe, initData] = useInitData();
  const [chapters, setChapters] = useState([]);
  const [jwtUpdated, setJwtUpdated] = useState();
  const [purchased, setPurchased] = useState();
  const [purchaseModalVisiblity, setPurchaseModalVisiblity] = useState(false);
  const [paymentCallbackVisiblity, setPaymentCallbackVisiblity] = useState(false)
  const [price, setPrice] = useState();
  const [discounted, setDiscounted] = useState()
  const [undiscountedPrice, setUndiscountedPrice] = useState()
  const [remainingDiscounts, setRemainingDiscounts] = useState()
  let status = new URLSearchParams(window.location.search).get("Status")
  let authority = new URLSearchParams(window.location.search).get("Authority")
  let paymentInfo
  useEffect(() => {
    if (status === "OK") {
      paymentInfo =
      {
        authority: authority,
        status: status
      }
      axios.get(
        `https://scambot.levothyroxine.uno/api/confirm_payment?authority=${authority}&status=${status}`,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('jwt-token')}` } }
      ).then(
        setPaymentCallbackVisiblity(true)
      ).catch(
        console.log('err')
      )

    }
    console.log(paymentInfo)
  }, [])
  window.Telegram.WebApp.expand();
  window.Telegram.WebApp.disableVerticalSwipes()
  let jsonData = {
    "init_data": initData,
    "hash_str": initDataUnsafe.hash
  }
  const showPurchaseModal = () => {
    axios.get('https://scambot.levothyroxine.uno/api/price',
      { headers: { 'Authorization': `Bearer ${localStorage.getItem('jwt-token')}` } },
    ).then(response => {
      if (response.data.discounted_price) {
        setPrice(response.data.discounted_price)
        setDiscounted(true)
        setUndiscountedPrice(response.data.price)
        setRemainingDiscounts(response.data.remaining)
      } else {
        setPrice(response.data.price)
        setDiscounted(false)
      }
    }
    )
    console.log()
    setPurchaseModalVisiblity(true)
  }
  const handleConfirmPurchase = () => {
    axios.get('https://scambot.levothyroxine.uno/api/create_payment',
      { headers: { 'Authorization': `Bearer ${localStorage.getItem('jwt-token')}` } },
    ).then(
      response => {
        window.location.href = `https://sandbox.zarinpal.com/pg/StartPay/${response.data}`
      }
    )
  }
  useEffect(() => {
    axios.post('https://scambot.levothyroxine.uno/api/get_user', JSON.stringify(jsonData), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(
        response => {
          localStorage.setItem('jwt-token', response.data.access_token)
          localStorage.setItem('purchased', response.data.purchased)
          setPurchased(response.data.purchased)
          setJwtUpdated(true)
        }
      )
  }, []
  )

  useEffect(() => {
    if (jwtUpdated) {
      console.log('trying to get chapters')
      axios.get('https://scambot.levothyroxine.uno/api/chapters', { headers: { 'Authorization': `Bearer ${localStorage.getItem('jwt-token')}` } })
        .then(response => {
          setChapters(response.data)
        })
        .catch(error => {
          console.error(error);
        })
    }
  }, [jwtUpdated]
  )
  let priceCard
  if (discounted) {
    priceCard = (
      <div className='Purchase-modal_price-card'>
        <div>
          <div className='Purchase-modal_price-card-info'>
            <p className="m-1" style={{ fontSize: '1rem' }}>مادام العمر</p>
            <p className="m-2" style={{ textDecoration: 'line-through' }}>
              {`${undiscountedPrice / 10 / 1000000} میلیون تومان`}
            </p>
            <p>
              {`${price / 10 / 1000000} میلیون تومان`}
            </p>
            <p>
              {`${remainingDiscounts} نفر باقی مانده!`}
            </p>
          </div>
        </div>
        <CheckBox className="Purchase-modal_price-card-check"></CheckBox>
      </div>
    )
  } else {
    priceCard = (
      <div className='Purchase-modal_price-card'>
        <p className="m-1" style={{ rotate: '45deg', top: '15rem', right: '0.5rem', position: 'absolute', fontSize: '1.15rem', animation: 'glow 1s ease-in-out infinite alternate' }}>مادام العمر</p>
        <p style={{ animation: 'glow2 1s ease-in-out infinite alternate' }}>
          {`${price / 10 / 1000000} میلیون تومان`}
        </p>
      </div>
    )
  }
  return (
    <div className="App" dir="rtl">
      <Dialog dir='rtl' className='Purchase-modal payment' maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.40)' }} visible={purchaseModalVisiblity} style={{ width: '88vw' }} onHide={() => { if (!purchaseModalVisiblity) return; setPurchaseModalVisiblity(false); }}>
        <div className='Purchase-modal_ins1'>
          <img src={mainLogo} className='Purchase-modal_logo'></img>
          <div className='Purchase-modal_name'>خرید اشتراک ربات</div>
          <div className='Purchase-modal_ins2'>
            <div className='Purchase-modal_method'>

              درگاه بانکی
            </div>
            {priceCard}
          </div>
          <button className='Purchase-modal__confirm-button' onClick={handleConfirmPurchase} >خرید اشتراک</button>
          <button className='Purchase-modal__close-button' onClick={() => { if (!purchaseModalVisiblity) return; setPurchaseModalVisiblity(false); }}>فعلا نه</button>
        </div>
      </Dialog>
      <Dialog dir='rtl' className='Purchase-modal' visible={paymentCallbackVisiblity} style={{ width: '50vw' }} onHide={() => { if (!paymentCallbackVisiblity) return; setPaymentCallbackVisiblity(false); window.location.href = "https://scambot.levothyroxine.uno/"; }}>
        <p className="m-0">
          اشتراک شما با موفقیت فعال شد!
        </p>
      </Dialog>
      <div className='Wrapper'>
        <Intro purchased={purchased} />
        {/* {chapters && <ChapterList chapters={chapters} />} */}
      </div>
      <div className='Chapters-container'>
        <div className='Chapters-heading_name'>سرفصل های فست مانی</div>
        <Accordion activeIndex={null} className='Accordion'>

          {chapters.map((chapter, index) => {
            return (<AccordionTab header={chapter.title} headerStyle={{ borderTop: (index === 0) ? 'none' : '2px solid rgba(255, 255, 255, 0.3)', fontSize: '0.8rem' }}>
              <Chapter title={chapter.title} purchased={purchased} onDownload={showPurchaseModal} key={chapter._id} description={chapter.description} sections={chapter.sections} />
            </AccordionTab>)
          })}
        </Accordion>
      </div>
      {!purchased && <button className='Button-purchase' onClick={showPurchaseModal}>خرید اشتراک</button>}
    </div>
  );
}


export default App;
