// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/yekan-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Iranian_Sans_Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Iranian Sans Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Yekan";
  src: local("Yekan"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Iran Sans";
  src: local("Iranian Sans"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Iran Sans Bold";
  src: local("Iranian Sans Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE;aACW;AACb;;AAEA;EACE,oBAAoB;EACpB;8DACqD;EACrD,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB;8DAC2D;EAC3D,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B;8DAC2D;EAC3D,iBAAiB;AACnB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #000000;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: \"Yekan\";\n  src: local(\"Yekan\"),\n    url(\"./fonts/yekan-regular.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"Iran Sans\";\n  src: local(\"Iranian Sans\"),\n    url(\"./fonts/Iranian_Sans_Medium.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"Iran Sans Bold\";\n  src: local(\"Iranian Sans Bold\"),\n    url(\"./fonts/Iranian\\ Sans\\ Bold.ttf\") format(\"truetype\");\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
