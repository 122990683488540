import './Video.css'
function Video(props) {
    function createIframe() {
        return {__html: `<div class="r1_iframe_embed"><iframe src="https://player.arvancloud.ir/index.html?config=${props.config_url}" style="border:0 #ffffff none;" name="20561726-7bcf-42a0-b2cd-532e3b7994cd.mp4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`};
    }

    return(
        <div className='Video' style={{display:'block'}}>
        <div dangerouslySetInnerHTML={createIframe()}></div>
        </div>
    )
}

export default Video